<template>
  <div class="all">
    <AppHeader/>
    <div class="main">

      <el-row :gutter="50">
        <el-col :span="8">
          <el-image :src="src" lazy style="width: 100%"/>
        </el-col>
        <el-col :span="16">
          <h3>{{title}}</h3>
          <p style="margin: 20px 0 30px 0;">擅长分类：{{desc}}</p>
          <p style="background: rgba(64,64,64,0.1);border: 1px solid #e5e5e5;border-radius: 4px;padding: 10px;line-height: 50px" v-html="change()"></p>
        </el-col>
      </el-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import Footer from "@/components/Footer";

export default {
name: "expert",
  components: {AppHeader, Footer},
  data(){
    return{
      title:null,
      src:null,
      id:null,
      desc:null,
      content:null,
    }
  },
  created() {
    this.id=this.$route.query.id;
    this.src=this.$route.query.src;
    this.title=this.$route.query.title;
    this.desc=this.$route.query.desc;
    this.content=this.$route.query.content;
  },
  methods:{
    change(){
      return this.content.replace(/\n/gm,"<br/>");
    },
  }
}
</script>

<style scoped>
.all{
  min-width: 960px;
}
.main{
  text-align: left;
  margin: 110px 10% 50px 10%;
}
</style>